import styled from 'styled-components';

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  font-size: 18px;
  line-height: 1.3;
`;

export const Address = styled.div``;

export const Links = styled.div`
  color: #222;

  ul {
    list-style: none;
    padding: 0;

    li a {
      color: #222;
    }
  }
`;

export const Body = styled.div``;
