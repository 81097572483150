import React from 'react';
import { withTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import SEO from '../../components/seo';

import { Header, Body, Links, Address } from './styles';

const GET_PIERCER_DATA = gql`
  query Piercer($id: Int!) {
    piercer(id: $id) {
      nickname
      firstName
      lastName
      role
      photo
      socialLinks
      content {
        language
        favouritePiercing
        inFreeTime
        otherModifications
        otherWorkPlace
        preferredContact
        startWorkExperience
        trainingAndCertifications
        typesOfPiercings
        workMostEmphasis
        workMostLike
        workPlace
      }
    }
  }
`;

const ROLE_MAPPING = {
  A_1: 'Prezeska',
  A_2: 'Wiceprezeska',
  A_3: 'Skarbnik',
  A_4: 'Członek',
};

const Piercer = (props) => {
  const { t } = props;
  const { data } = useQuery(GET_PIERCER_DATA, {
    variables: { id: props.pageContext.piercer.id },
  });
  const piercerData = data ? data.piercer : null;
  const piercer = piercerData || props.pageContext.piercer;
  const piercerNickname = piercer.nickname ? `"${piercer.nickname}"` : '';
  const piercerName =
    piercer.firstName && piercer.lastName
      ? `${piercer.firstName} ${piercerNickname} ${piercer.lastName}`
      : piercer.nickname;
  const piercerContent = piercer.content.filter((content) =>
    content.language.toLowerCase() === 'pl' ? content : null
  )[0];

  return (
    <>
      <SEO title={piercerName} />
      <h1>{piercerName}</h1>
      <h3>{ROLE_MAPPING[piercerName.role]}</h3>

      <Header>
        <div>
          <h2>{t('workPlace')}:</h2>
          <Address>
            {piercerContent.workPlace.map((addressLine, idx) => (
              <span key={idx}>
                {addressLine.startsWith('www') ||
                addressLine.startsWith('http') ? (
                  <OutboundLink
                    href={'//' + addressLine}
                    className="link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {addressLine}
                  </OutboundLink>
                ) : (
                  addressLine
                )}
                <br />
              </span>
            ))}
          </Address>

          {piercerContent.otherWorkPlace && (
            <>
              <h2>{t('otherWorkPlace')}:</h2>
              <Address>
                {piercerContent.otherWorkPlace.map((addressLine, idx) => (
                  <span key={idx}>
                    {addressLine.startsWith('www') ||
                    addressLine.startsWith('http') ? (
                      <OutboundLink
                        href={addressLine}
                        className="link"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {addressLine}
                      </OutboundLink>
                    ) : (
                      addressLine
                    )}
                    <br />
                  </span>
                ))}
              </Address>
            </>
          )}

          <Links>
            <h2>{t('socialLinks')}:</h2>
            <ul>
              {piercer.socialLinks.map((link, idx) => (
                <li key={idx}>
                  <OutboundLink
                    href={link.startsWith('http') ? link : `https://${link}`}
                    className="link"
                  >
                    {link.startsWith('http') ? link : `https://${link}`}
                  </OutboundLink>
                </li>
              ))}
            </ul>
          </Links>

          <h2>{t('preferredContact')}:</h2>
          {piercerContent.preferredContact.map((info, idx) => (
            <span key={idx}>{info}</span>
          ))}

          <h2>{t('startWorkExperience')}:</h2>
          {piercerContent.startWorkExperience}

          {piercerContent.typesOfPiercings && (
            <>
              <h2>{t('typesOfPiercings')}:</h2>
              <ul>
                {piercerContent.typesOfPiercings.map((piercingType, idx) => (
                  <li key={idx}>{piercingType}</li>
                ))}
              </ul>
            </>
          )}
        </div>

        {piercer.photo && (
          <img src={piercer.photo} width="100%" alt={piercerName} />
        )}
      </Header>
      <Body>
        {piercerContent.otherModifications && (
          <>
            <h2>{t('otherModifications')}:</h2>
            <ul>
              {piercerContent.otherModifications.map((modification, idx) => (
                <li key={idx}>{modification}</li>
              ))}
            </ul>
          </>
        )}

        {piercerContent.trainingAndCertifications && (
          <>
            <h2>{t('trainingAndCertifications')}</h2>
            <ul>
              {piercerContent.trainingAndCertifications.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </>
        )}

        <h2>{t('favouritePiercing')}:</h2>
        <>{piercerContent.favouritePiercing}</>

        <h2>{t('workMostEmphasis')}:</h2>
        {piercerContent.workMostEmphasis}

        <h2>{t('workMostLike')}:</h2>
        {piercerContent.workMostLike}

        <h2>{t('inFreeTime')}:</h2>
        {piercerContent.inFreeTime}
      </Body>
    </>
  );
};

export default withTranslation()(Piercer);
